import * as React from "react";
import HomeImg from "../../../assets/marketing/home.webp";

export default function Header() {
  const navItems = [
    { label: "Services", link: "/services" },
    { label: "About us", link: "/about-us" },
    { label: "Contact us", link: "/contact" },
    { label: "Blog", link: "/blog" }
  ];

  return (
    <div className="mx-auto px-[20px] font-nourd-medium flex flex-wrap gap-5 justify-between w-full text-base font-medium leading-none max-w-[1180px] text-neutral-800 max-md:max-w-full">
      <img
        loading="lazy"
        src={HomeImg}
        alt="Company Logo"
        className="object-contain shrink-0 max-w-full aspect-[1.75] w-[105px] image-unset"
      />
      <nav className="flex gap-8 my-auto">
        {navItems.map((item, index) => (
          <a
            key={index}
            href={item.link}
            className="border-neutral-800"
            tabIndex="0"
          >
            {item.label}
          </a>
        ))}
      </nav>
    </div>
  );
}