import * as React from "react";
import SignUpImg from "../../../assets/marketing/signup-bg.webp";

export default function ContactForm() {
  return (
    <div className="max-w-[1280px] w-full mx-auto">
      <div className="flex relative flex-col items-end self-stretch px-[50px] py-10 mt-16 w-full text-lg text-white min-h-[586px]">
        {/* <img
          loading="lazy"
          src={SignUpImg}
          className="m-0 rounded-none object-cover absolute inset-0 size-full"
        /> */}
        <video
          className="m-0 rounded-none object-cover absolute inset-0 size-full"
          autoPlay
          loop
          muted
          playsInline
        >
          <source
            src="https://cdn.prod.website-files.com/617c3138ae96290382b971a0/6187ff1a80976a1fd1f8dcd5_how_to_tie_your_scarf_trimmed-transcode.mp4"
            type="video/mp4"
          />
          <source
            src="https://cdn.prod.website-files.com/617c3138ae96290382b971a0/6187ff1a80976a1fd1f8dcd5_how_to_tie_your_scarf_trimmed-transcode.webm"
            type="video/webm"
          />
        </video>
        <form className="relative w-[430px] max-w-full">
          <h2 className="font-montserrat-regular text-[27px] font-medium leading-[40.5px] text-left md:mr-1 md:max-w-full">
            Sign up for Jane rothe Updates
          </h2>

          <div className="mt-9">
            <label
              htmlFor="firstName"
              className="font-nourd-regular block  text-[18px] font-normal leading-[18px] text-left"
            >
              First name <span className="text-fuchsia-600">*</span>
            </label>
            <input
              type="text"
              id="firstName"
              required
              placeholder="Enter your first name"
              className="w-full px-5 py-4 mt-3 leading-none rounded-lg border border-white border-solid shadow-[0px_0px_2px_rgba(0,0,0,0.15)] text-black"
            />
          </div>

          <div className="mt-6">
            <label
              htmlFor="lastName"
              className="font-nourd-regular block text-[18px] font-normal leading-[18px] text-left"
            >
              Last name <span className="text-fuchsia-600">*</span>
            </label>
            <input
              type="text"
              id="lastName"
              required
              placeholder="Enter your last name"
              className="w-full px-5 py-4 mt-3 leading-none rounded-lg border border-white border-solid shadow-[0px_0px_2px_rgba(0,0,0,0.15)] text-black"
            />
          </div>

          <div className="mt-6">
            <label
              htmlFor="email"
              className="font-nourd-regular block text-[18px] font-normal leading-[18px] text-left"
            >
              Email address <span className="text-fuchsia-600">*</span>
            </label>
            <input
              type="email"
              id="email"
              required
              placeholder="Enter your email address"
              className="w-full px-5 py-4 mt-3 leading-none rounded-lg border border-white border-solid shadow-[0px_0px_2px_rgba(0,0,0,0.15)] text-black"
            />
          </div>
          <div className="mt-4 text-base leading-5">
            <div class="flex items-center space-x-3">
              <div className="self-start"><input
                type="checkbox"
                id="customCheckbox"
                class="w-6 h-6 border-2 border-gray-500 rounded-md appearance-none cursor-pointer relative checked:border-clientLightYellow checked:bg-transparent checked:before:content-['✓'] checked:before:text-clientLightYellow checked:before:text-xl checked:before:font-bold checked:before:flex checked:before:absolute checked:before:top-1/2 checked:before:left-1/2 checked:before:transform checked:before:-translate-x-1/2 checked:before:-translate-y-1/2"
              /></div>
              <div className="flex-auto">
                By entering your email address you are accepting our{" "}
                <a href="#" className="text-fuchsia-600">
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a href="#" className="text-fuchsia-600">
                  Privacy
                </a>{" "}
                &{" "}
                <a href="#" className="text-fuchsia-600">
                  Cookie Policy.
                </a>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="font-nourd-regular text-[18px] relative px-12 py-4 mt-9  font-medium leading-none rounded-lg border-2 border-fuchsia-600 border-solid shadow-[0px_0px_2px_rgba(0,0,0,0.15)]"
          >
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
}
