import * as React from "react";
import style from "./HeroSection.module.css";
import LandingPageImg from "../../../assets/marketing/landing-banner.png";
import LogoImg from "../../../assets/marketing/janeRotheLogo.webp";
export default function HeroSection() {
  return (
    <div className="flex relative flex-col items-center self-stretch px-20 pt-96 pb-9 mt-2.5 w-full text-center text-white uppercase min-h-[752px] max-md:px-5 max-md:pt-24 max-md:max-w-full">
      <img
        loading="lazy"
        src={LandingPageImg}
        alt="Hero background"
        className={`${style.heroBg} `}
      />
      <div className="flex relative flex-col items-center max-w-full w-[788px]">
        <img
          loading="lazy"
          src={LogoImg}
          alt="Company branding"
          className="object-contain max-w-full aspect-[4.59] w-[495px] h-auto image-unset"
        />
        <div className=" mt-5 text-5xl font-medium leading-[72px] max-md:max-w-full max-md:text-4xl max-md:leading-[70px]">
          Growth Strategies & Marketing for{" "}
          <span>
          <span className="text-primary">REALTORS<span class="text-[20px] align-super"> ®</span></span>
          </span>
        </div>
        <button 
          className=" py-3 mt-6 max-w-full text-base font-bold leading-relaxed rounded-md border-2 border-white border-solid bg-black bg-opacity-10 w-[230px] max-md:px-5"
          tabIndex="0"
        >
          SCHEDULE A MEETING
        </button>
      </div>
    </div>
  );
}