import React, { useRef } from "react";
// import MainLayout from "../../layouts/MainLayout";
// import LandingSection from "../../section/LandingSection";
import Header from "./Header";
import HeroSection from "./HeroSection";
import HomeImg2 from "../../../assets/marketing/home2.webp";
import HomeImg1 from "../../../assets/marketing/home1.webp";
import HomeImgGroup from "../../../assets/marketing/homegruopnew.webp";
import LearnMoreImg from "../../../assets/marketing/learnmore.webp";
import HomeCenterImg from "../../../assets/marketing/home-center.webp";
import LearnMoreImg2 from "../../../assets/marketing/learnimg2.webp";
import slider1 from "../../../assets/marketing/slider1.webp";
import slider2 from "../../../assets/marketing/slider2.webp";
import slider3 from "../../../assets/marketing/slider3.webp";
import arrow from "../../../assets/marketing/arrow.webp";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { EffectCoverflow, EffectCards, Pagination, Navigation } from 'swiper/modules';
import LandingFooter from "../../landingFooter/LandingFooter";
import ContactForm from "./ContactForm";
// import 'swiper/css/effect-cards';
// import "swiper/css/effect-coverflow";
import "./styles.css";

const images = [
  slider1,
  slider2,
  slider3
];
const navigationItems = [
  { id: 1, label: 'Services', href: '/services' },
  { id: 2, label: 'About us', href: '/about' },
  { id: 3, label: 'Contact us', href: '/contact' },
  { id: 4, label: 'Blog', href: '/blog' }
];
const services = [
  "Best for Individual Agents",
  "Business Consultation & Growth Strategies",
  "Marketing Design & Execution",
  "Email Marketing",
  "Social Media Marketing"
];

const marketingData = {
  title: "Brokerage Marketing & Strategy Package",
  description: [
    "Best for boutique brokerages who are looking to grow",
    "Comprehensive Marketing Director Services",
  ]
};

const LandingPage = () => {
  const swiperRef = useRef(null); // Create a reference to access Swiper instance
  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext(); // Go to the next slide
    }
  };
  return (
    <>
      <div className="mx-auto text-justify max-w-7xl flex overflow-hidden flex-col items-center pt-2.5 bg-white">
        <Header></Header>
        <HeroSection></HeroSection>
        <div className="mt-16 w-full max-w-[1280px] md:px-[50px] px-[25px] md:mt-10 md:max-w-full">
          <div className="grid md:grid-cols-2 gap-[25px]">
            <div className="mx-auto my-auto">
              <div className="flex shrink-0 w-full rounded-3xl h-auto max-w-[595px] md:mt-6">
                <img alt="Home group" className="flex shrink-0 max-w-full  rounded-tl-[20px] h-auto w-[595px] md:mt-6 object-cover size-full m-0 p-0" src={HomeImgGroup}></img>
              </div>
            </div>
            <div className="mx-auto my-auto">
              <div className="flex flex-col items-start self-stretch my-auto mt-12 md:mt-10 md:max-w-full">
                <div className="text-raisinBlack font-lejour uppercase text-[clamp(40px,_3vw,_55px)] font-normal leading-[55px] [text-underline-position:from-font] [text-decoration-skip-ink:none]">
                  What we do
                </div>
                <div className="text-raisinBlack font-montserrat-regular mt-5 text-[clamp(20px,_3vw,_28px)] font-medium leading-tight md:mt-10 md:max-w-full">
                  Power of stories and connections
                </div>
                <div className="font-nourd-regular self-stretch mt-5 text-lg leading-7 text-clientDarkCharcoal md:max-w-full">
                  At The Jane Rothe Co., we recognize that stories are more than just words—they are powerful tools that connect people, build trust, and keep you top of mind. Our marketing approach is deeply rooted in storytelling, crafting narratives that resonate with potential clients and strengthen connections within your network so that when real estate needs arise, you're the first person they think of and call.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 w-full max-w-[1280px] md:px-[50px] px-[25px]">
          <div className="grid md:grid-cols-1 tabletSlider:grid-cols-2 gap-[25px]">
            <div className="mx-auto">
              <div className="flex flex-col items-start self-stretch my-auto md:max-w-full">
                <div className="font-montserrat-regular text-[28px] font-medium leading-[42px] text-left [text-underline-position:from-font] [text-decoration-skip-ink:none]">
                  Empowering Real Estate Agents to Build Lasting Relationships
                </div>

                <div className="font-nourd-regular self-stretch mt-5 text-lg leading-7 text-clientDarkCharcoal md:max-w-full">
                  We believe that successful real estate marketing is built on relationships, not just transactions. At The Jane Rothe Co., we empower real estate agents by providing them with the tools, strategies, and insights to foster genuine, long-lasting connections with their clients. We focus on helping agents build trust and loyalty within their communities, ensuring they are well-positioned for repeat business and referrals. Our approach is about cultivating relationships that go beyond a single deal—relationships that lead to sustained growth and ongoing success.
                </div>
              </div>
            </div>
            <div className="mx-auto">
              <div className="flex relative  mx-auto max-w-[590px] tabletMore:max-h-[550px] tabletMore:h-[550px] h-auto rounded-3xl w-full" >
                <div className="absolute z-[999] arrowDiv left-[360px] top-1/2 transform -translate-y-1/2 translate-x-1/2">
                  <button
                    onClick={handleNextSlide}
                  ><img className="max-w-[80px] max-h-[40px] h-auto  w-full object-cover m-0 p-0" alt="arrow" src={arrow} /></button>
                </div>
                <Swiper
                  ref={swiperRef}
                  slidesPerView={3}
                  // spaceBetween={-90}
                  centeredSlides={false}
                  loop={true}
                  speed={2000}
                  // navigation={true}
                  pagination={{
                    clickable: false,
                  }}
                  onSlideChange={(swiper) => {
                    const slides = document.querySelectorAll('.swiper-slide');
                    slides.forEach((slide, index) => {
                      if (index === swiper.activeIndex) {
                        slide.style.transform = 'scale(1) rotate(0deg) translateY(0)';
                        slide.style.transition = 'transform 2s ease-in-out';
                      } else {
                        slide.style.transform = 'translateX(0px) scale(0.8)';
                        slide.style.transition = 'transform 2s ease-in-out, opacity 2s ease-in-out';
                      }
                    });
                  }}
                  // effect={"coverflow"}
                  // coverflowEffect={{
                  //   rotate: 50,
                  //   stretch: 0,
                  //   depth: 100,
                  //   modifier: 1,
                  //   slideShadows: true,
                  // }}
                  modules={[]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <img
                      src={slider1}
                      alt={`Slide 1`}
                      className="w-full h-full object-cover m-0 p-0 "
                    /></SwiperSlide>
                  <SwiperSlide><img
                    src={slider2}
                    alt={`Slide 2`}
                    className="w-full h-full object-cover m-0 p-0 "
                  /></SwiperSlide>
                  <SwiperSlide><img
                    src={slider3}
                    alt={`Slide 3`}
                    className="w-full h-full object-cover m-0 p-0 "
                  /></SwiperSlide>
                  <SwiperSlide> <img
                    src={slider3}
                    alt={`Slide 4`}
                    className="w-full h-full object-cover m-0 p-0 "
                  /></SwiperSlide>
                  {/* <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </div>

        <div className="md:mt-[70px] mt-[50px] mb-[21px] px-[50px] max-w-[1280px]">
          <p className="font-montserrat-regular max-w-[940px] mb-[21px] mx-auto text-raisinBlack text-[28px] font-medium leading-[42px] text-center">
            Blending the Familiarity of Traditional Marketing with Cutting-Edge Strategies
          </p>
          <p className="text-justify font-nourd-regular text-clientDarkCharcoal text-[18px] font-normal leading-[27px] break-words">
            Our approach combines the best of both worlds: the reliability of traditional marketing with the innovation of modern strategies. At The Jane Rothe Co., we leverage time-tested methods that build trust and familiarity while integrating fresh, forward-thinking techniques tailored to today’s fast-paced real estate market. By staying on top of emerging trends and technologies, we ensure that our clients are not just keeping up but leading the way in their marketing efforts, creating impactful connections that drive real results.
          </p>
          <div className="flex justify-center my-[18px]">
            <Link to="/about-us"><img className="m-0 max-w-[143px] w-full h-auto" alt="learnmore" src={LearnMoreImg}></img></Link>
          </div>
          <img className="m-0 max-w-[1180px] w-full h-auto rounded-[8px]" src={HomeCenterImg}></img>
        </div>

        <div className="mx-auto max-w-[1280px] w-full">
          <div className="px-[50px]">
            <div className="relative mx-auto max-w-[1180px] w-full h-[500px]">
              <img
                loading="lazy"
                src={HomeImg1}
                alt="Package Image"
                className="object-cover inset-0 size-full w-full m-0 rounded-[8px]"
              />
              <div className="absolute bottom-0 left-0 w-full max-w-[560px] rounded-bl-[8px] h-auto flex-wrap gap-4 items-start px-8 py-8 bg-black bg-opacity-60 md:px-5">
                <div className="text-white flex flex-col grow shrink-0 self-start basis-0 w-fit text-left">
                  <h2 className="self-start text-2xl font-medium uppercase ">
                    The Premier Package
                  </h2>
                  <div className="z-10 mt-9 leading-7 md:mr-1 md:max-w-full">
                    {services.map((service, index) => (
                      <React.Fragment key={index}>
                        <li> {service}</li>
                      </React.Fragment>
                    ))}
                  </div>

                </div>
                <div className="flex justify-end">
                  <Link to="/services"
                    className="self-end -mt-3"
                    tabIndex={0}
                    aria-label="Learn more about The Premier Package"
                  >
                    <img className="m-0 rounded-none max-w-[118px] w-full h-auto" src={LearnMoreImg2} alt="learnmore"></img>
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex relative flex-col items-end  pb-80 mt-8 text-white rounded-lg w-full h-auto md:pb-24 md:pl-5 md:mt-10">
              <img
                loading="lazy"
                src={HomeImg2}
                alt="Home Image"
                className="object-cover absolute inset-0 size-full m-0 rounded-[8px]"
              />
              <div className="max-w-[560px] rounded-tr-[8px] w-full h-auto text-left flex relative flex-wrap gap-5 items-start px-8 py-8 mb-0 rounded-none bg-black bg-opacity-60 md:px-5 md:mb-2.5">
                <div className="flex flex-col grow shrink-0 self-start basis-0 w-fit md:max-w-full">
                  <div className="self-start text-2xl font-medium leading-10 uppercase">
                    {marketingData.title}
                  </div>
                  <div className="mt-4 font-nourd-regular self-start text-[15px] font-normal leading-[27px] md:max-w-full">
                    {marketingData.description.map((line, index) => (
                      <React.Fragment key={index}>
                        <li>{line}</li>
                      </React.Fragment>
                    ))}
                  </div>
                  <div
                    className="flex shrink-0 self-end "
                    role="presentation"
                    aria-hidden="true"
                  >
                    <Link to="/services"><img className="m-0 rounded-none max-w-[118px] w-full h-auto" src={LearnMoreImg2} alt="learnmore"></img></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm></ContactForm>
      <LandingFooter></LandingFooter>
    </>
  );
};

export default LandingPage;
